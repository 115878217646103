<template>
  <common-card :title="'今日交易用户数'" :showData="'423'">
      <template>
        <v-chart class="today-chart" :options="option"></v-chart>
      </template>
      <template #footer>
      退货率&nbsp;&nbsp;<span class="emphasis">5.65%</span>
      </template>
  </common-card>
</template>

<script>
import CommonCard from '@/components/commonCard/index'
export default {
  name: 'TodayUsersView',
  components: {
    CommonCard
  },
  data () {
    return {
      option: {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: 0,
          right: 0,
          bottom: 0,
          top: 0
        },
        xAxis: [
          {
            type: 'category',
            data: ['00:00', '02:00', '04:00', '06:00', '08:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00'],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            show: false
          }
        ],
        series: [
          {
            name: '直接访问',
            type: 'bar',
            barWidth: '50%',
            data: [300, 52, 200, 334, 390, 330, 350, 280, 300, 200, 80, 50]
          }
        ],
        color: '#3398db'
      }
    }
  }
}
</script>

<style scoped>

</style>
