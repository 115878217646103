<template>
  <div id="main" style="width: 600px; height: 400px;">
    charts
  </div>
</template>

<script>
export default {
  name: 'EchartsDemo',
  mounted () {
    const myEcharts = this.$echarts.init(document.getElementById('main'))
    myEcharts.setOption({
      title: {
        text: 'ECharts 入门示例'
      },
      tooltip: {},
      xAxis: {
        data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
      },
      yAxis: {},
      series: [{
        name: '销量',
        type: 'bar',
        data: [5, 20, 36, 10, 10, 20]
      }]
    })
  }
}
</script>

<style scoped>

</style>
