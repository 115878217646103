import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home/index.vue'
import Test from '@/views/Test/index.vue'
import EchartsDemo from '@/views/Test/EchartsDemo'
import VueEchartsDemo from '@/views/Test/VueEchartsDemo'
import VChartsDemo from '@/views/Test/VChartsDemo'
import LiquidFillDemo from '@/views/Test/LiquidFillDemo'
import WordCloudDemo from '@/views/Test/WordCloudDemo'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/test',
    component: Test
  },
  {
    path: '/echarts',
    name: 'echarts',
    component: EchartsDemo
  },
  {
    path: '/vueEcharts',
    name: 'vue-echarts',
    component: VueEchartsDemo
  },
  {
    path: '/vcharts',
    name: 'vcharts',
    component: VChartsDemo
  },
  {
    path: '/ball',
    name: 'ball',
    component: LiquidFillDemo
  },
  {
    path: '/word',
    name: 'word',
    component: WordCloudDemo
  }
]

const router = new VueRouter({
  routes
})

export default router
