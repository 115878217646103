<template>
    <!-- 水球图,安装命令: pnpm i liquidfill@2
         文档: npm搜索 echarts-liquidfill -->
  <div>
    <v-chart :options="option" />
    <ve-liquidfill :data="data" />
  </div>
</template>

<script>
import 'echarts-liquidfill'
export default {
  data () {
    return {
      option: {
        series: [{
          type: 'liquidFill',
          data: [0.6]
        }]
      },
      data: {
        columns: ['title', 'percent'],
        rows: [{ title: '用户转换率', percent: 0.65 }]
      }
    }
  }
}
</script>

<style>

</style>
