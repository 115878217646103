<template>
  <div class="container">
    <!-- 百度地图 -->
    <div class="map-left">
      <!-- 简单案例 -->
        <!-- <baidu-map class="map" :center="markerPoint" :zoom="16">
          <bm-marker
            :position="markerPoint"
            :dragging="true"
            @click="infoWindowOpen"
          >
            <bm-info-window
              :show="show"
              @close="infoWindowClose"
              @open="infoWindowOpen"
              style="font-size: 13px"
            >
              北京xxxx <br /><br />
              地址：北京市
            </bm-info-window>
          </bm-marker>
        </baidu-map> -->

        <div class="map">
        <el-input
          v-model="addressKeyword"
          placeholder="请输入地址来直接查找相关位置"
        ></el-input>
        <!-- 给地图加点击事件getLocationPoint，点击地图获取位置相关的信息，经纬度啥的 -->
        <!-- scroll-wheel-zoom：是否可以用鼠标滚轮控制地图缩放，zoom是视图比例 -->
        <baidu-map
          class="bmView"
          :scroll-wheel-zoom="true"
          :center="location"
          :zoom="zoom"
          @click="getLocationPoint"
          ak="rSxeR7Rky93j4pbWdaeD2ntR4k7fN2hH"
        >
          <bm-view style="width: 100%; height: 560px; flex: 1"></bm-view>
          <bm-local-search
            :keyword="addressKeyword"
            :auto-viewport="true"
            style="display: none"
          ></bm-local-search>
        </baidu-map>
      </div>
    </div>
    <!-- 右侧两个小图表 -->
     <div class="map-right">
        <!-- 水球图 -->
        <el-card class="water-ball" shadow="hover">
            <div slot="header" class="card-title">用户月同比增长</div>
            <div class="chart">
              <ve-liquidfill :data="userData" :settings="settings" height="200px"/>
            </div>
        </el-card>
        <!-- 词云图 -->
        <el-card class="word-cloud" shadow="hover">
            <div slot="header" class="card-title">热门搜索</div>
            <ve-wordcloud :data="wordCloudData" height="200px"/>
        </el-card>
     </div>
  </div>
</template>

<script>
export default {
  name: 'MapView',
  data () {
    return {
      // 水球图数据
      userData: {
        columns: ['title', 'percent'],
        rows: [{ title: '用户转换率', percent: 0.2676 }]
      },
      wordCloudData: {
        columns: ['word', 'count', 'user'],
        rows: [
          {
            'word': '北京',
            'count': 2181,
            'user': 1221
          },
          {
            'word': '上海',
            'count': 7504,
            'user': 6875
          },
          {
            'word': '广州',
            'count': 5148,
            'user': 2540
          },
          {
            'word': '深圳',
            'count': 4137,
            'user': 3415
          },
          {
            'word': '南京',
            'count': 4473,
            'user': 1223
          },
          {
            'word': '杭州',
            'count': 2862,
            'user': 2350
          },
          {
            'word': '合肥',
            'count': 2363,
            'user': 1015
          },
          {
            'word': '济南',
            'count': 667,
            'user': 265
          },
          {
            'word': '太原',
            'count': 9189,
            'user': 3687
          },
          {
            'word': '成都',
            'count': 8598,
            'user': 5543
          },
          {
            'word': '重庆',
            'count': 3482,
            'user': 1351
          },
          {
            'word': '苏州',
            'count': 8857,
            'user': 7697
          },
          {
            'word': '无锡',
            'count': 5881,
            'user': 4309
          },
          {
            'word': '常州',
            'count': 2089,
            'user': 1279
          },
          {
            'word': '温州',
            'count': 5552,
            'user': 4657
          },
          {
            'word': '哈尔滨',
            'count': 4611,
            'user': 4081
          },
          {
            'word': '长春',
            'count': 3120,
            'user': 3079
          },
          {
            'word': '大连',
            'count': 7613,
            'user': 2846
          },
          {
            'word': '沈阳',
            'count': 132,
            'user': 32
          },
          {
            'word': '拉萨',
            'count': 9584,
            'user': 1969
          },
          {
            'word': '呼和浩特',
            'count': 1407,
            'user': 850
          },
          {
            'word': '武汉',
            'count': 2877,
            'user': 2136
          },
          {
            'word': '南宁',
            'count': 3182,
            'user': 1600
          },
          {
            'word': '必胜客',
            'count': 3302,
            'user': 2911
          },
          {
            'word': '肯德基',
            'count': 1157,
            'user': 810
          },
          {
            'word': '麦当劳',
            'count': 557,
            'user': 359
          },
          {
            'word': '海底捞',
            'count': 1510,
            'user': 225
          },
          {
            'word': '美食',
            'count': 5932,
            'user': 3774
          },
          {
            'word': '商超',
            'count': 1350,
            'user': 606
          },
          {
            'word': '水果',
            'count': 197,
            'user': 187
          },
          {
            'word': '跑腿',
            'count': 2944,
            'user': 1187
          },
          {
            'word': '送药',
            'count': 6537,
            'user': 2050
          },
          {
            'word': '烩饭',
            'count': 4732,
            'user': 3884
          },
          {
            'word': '面条',
            'count': 1675,
            'user': 1036
          },
          {
            'word': '小龙虾',
            'count': 9311,
            'user': 3121
          },
          {
            'word': '牛肉',
            'count': 3067,
            'user': 110
          },
          {
            'word': '鸡腿',
            'count': 9651,
            'user': 861
          },
          {
            'word': '全家桶',
            'count': 1701,
            'user': 234
          },
          {
            'word': '麦乐鸡',
            'count': 3152,
            'user': 1945
          },
          {
            'word': '炭烤',
            'count': 7575,
            'user': 4571
          },
          {
            'word': '麻辣',
            'count': 3731,
            'user': 835
          },
          {
            'word': '冒菜',
            'count': 7531,
            'user': 3247
          }
        ]
      },
      // 水球图样式
      settings: {
        seriesMap: {
          '用户转换率': {
            // 水球图比例,控制大小
            radius: '80%',
            label: {
              // 设置默认的字体样式
              normal: {
                textStyle: {
                  fontSize: 30,
                  color: '#999',
                  insideColor: '#fff',
                  fontWeight: 'normal'
                }
              }
            },
            // 调整边框样式
            outline: {
              // 设置波形边缘与容器边框的距离
              borderDistance: 0,
              itemStyle: {
                borderWidth: 2,
                borderColor: '#ccc',
                // 隐藏边框的阴影效果
                shadowBlur: 0,
                shadowColor: 'transparent'
              }
            },
            // 将背景颜色设置为白色
            backgroundStyle: {
              color: '#fff'
            },
            itemStyle: {
              // 设置波浪颜色
              color: '#96e555',
              // 隐藏波浪的阴影效果
              shadowBlur: 0,
              shadowColor: 'transparent'
            }
          }
        }
      },
      // 默认地图坐标
      location: {
        lng: 116.404,
        lat: 39.915
      },
      // 放大倍数
      zoom: 12.8,
      markerPoint: { lng: 116.4, lat: 39.9 },
      show: false,
      // 查询关键字
      addressKeyword: ''
    }
  },
  methods: {
    infoWindowClose () {
      this.show = false
    },
    infoWindowOpen () {
      this.show = true
    }
  }
}
</script>

<style scoped lang="less">
.container {
  height: 600px;
  display: flex;
  margin: 10px;
  justify-content: space-between;
  .map-left {
    width: 78.665%;
    margin-right: 1.335%;
    border-radius: 4px;
  }
  .map-right {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .water-ball {
        flex: 1;
        border-radius: 4px;
        margin-bottom: 20px;
    }
    .word-cloud {
        flex: 1;
        border-radius: 4px;
    }
  }
}
.card-title {
  font-size: 14px;
  color: '#333';
}
.map {
  width: 100%;
  height: 600px;
}
</style>
