<template>
    <!-- 词云图,安装命令: pnpm i echarts-wordcloud@1
         文档: npm搜索 echarts-wordcloud -->
  <div class="container">
    <div id="main"></div>
  </div>
</template>

<script>
import 'echarts-liquidfill'
export default {
  data () {
    return {
      wordCloudData: [
        { 'name': '澎湃', 'value': 25 },
        { 'name': '暴晒', 'value': 11 },
        { 'name': '套件', 'value': 13 },
        { 'name': '测试', 'value': 8 },
        { 'name': '数据', 'value': 22 },
        { 'name': '分析', 'value': 17 },
        { 'name': '编程', 'value': 30 },
        { 'name': '算法', 'value': 19 },
        { 'name': '设计', 'value': 14 },
        { 'name': '用户', 'value': 28 },
        { 'name': '界面', 'value': 9 },
        { 'name': '体验', 'value': 21 },
        { 'name': '产品', 'value': 16 },
        { 'name': '市场', 'value': 27 },
        { 'name': '策略', 'value': 12 }
      ]
    }
  },
  methods: {
    renderWordCloudChart () {
      const chart = this.$echarts.init(document.getElementById('main'))
      chart.setOption({
        series: [
          {
            type: 'wordCloud',
            shape: 'cardioid ',
            keepAspect: true,
            // maskImage: maskImage,

            left: 'center',
            top: 'center',
            width: '80%',
            height: '80%',
            right: null,
            bottom: null,

            sizeRange: [12, 60],

            rotationRange: [-90, 90],
            rotationStep: 45,

            gridSize: 8,

            drawOutOfBound: false,

            layoutAnimation: true,

            textStyle: {
              normal: {
                fontFamily: 'sans-serif',
                fontWeight: 'bold',
                color: function () {
                  return (
                    'rgb(' +
                    [
                      Math.round(Math.random() * 256),
                      Math.round(Math.random() * 256),
                      Math.round(Math.random() * 256)
                    ].join(',') +
                    ')'
                  )
                }
              }
            },
            emphasis: {
              focus: 'self',
              textStyle: {
                // normal: {
                textShadowBlur: 20,
                textShadowColor: '#333'
                // },
              }
            },
            data: this.wordCloudData
          }
        ]
      })
    }
  },
  mounted () {
    this.renderWordCloudChart()
  }
}
</script>

<style scoped>
#main {
  height: 400px;
}
</style>
