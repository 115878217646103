<template>
  <div class="progress-view-wrapper">
    <div class="progress-view left">
      <el-card class="box-card" shadow="hover">
        <template slot="header">
          <span style="font-size: 14px; color: #333">关键词搜索</span>
        </template>
        <div class="chart">
           <div class="search-user">
              <div class="title">搜索用户数</div>
              <div class="num">101,969</div>
              <v-chart style="width: 314px; height: 50px;" :options="userOption"/>
           </div>

           <div class="search-num">
              <div class="title">搜索量</div>
              <div class="num">206,283</div>
              <v-chart style="width: 314px; height: 50px;" :options="numOption"/>
           </div>
        </div>

        <div class="table">
          <el-table
            :data="tableData"
            style="width: 100%">
            <el-table-column
              prop="index"
              label="排名"
              width="180">
            </el-table-column>
            <el-table-column
              prop="keyword"
              label="关键词"
              width="180">
            </el-table-column>
            <el-table-column
              prop="totalSearch"
              label="总搜索量">
            </el-table-column>
            <el-table-column
              prop="totalUsers"
              label="搜索用户数">
            </el-table-column>
            <el-table-column
              prop="CTR"
              label="点击率">
            </el-table-column>
          </el-table>
        </div>

        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="1000">
          </el-pagination>
        </div>

      </el-card>
    </div>

    <div class="progress-view right">
     <el-card class="box-card" shadow="hover">
        <template slot="header">
          <div class="right-header">
            <span style="font-size: 14px; color: #333">分类销售排行</span>
            <el-radio-group v-model="radio" size="small">
              <el-radio-button label="品类" />
              <el-radio-button label="商品" />
            </el-radio-group>
          </div>
        </template>
        <div class="pie-chart">
          <v-chart :options="pieOption" style="height: 420px; width: 688px; margin-top: 20px;"/>
        </div>

      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressView',
  data () {
    return {
      tableData: [
        { index: 1, keyword: '冒菜', totalSearch: 9569, totalUsers: 9185, CTR: '95.99%' },
        { index: 2, keyword: '无锡', totalSearch: 9382, totalUsers: 8425, CTR: '89.80%' },
        { index: 3, keyword: '小龙虾', totalSearch: 8231, totalUsers: 8083, CTR: '98.20%' },
        { index: 4, keyword: '济南', totalSearch: 8590, totalUsers: 6628, CTR: '77.16%' }
      ],
      userOption: {
        xAxis: {
          type: 'category',
          // 关闭横轴自带间隙
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          // 隐藏x轴
          show: false
        },
        // 鼠标悬浮显示数据
        tooltip: {
          // 设置只有鼠标悬浮在数据点之上才显示详细信息,
          // 之前使用的值都是axis,表示鼠标悬浮在坐标轴之上就显示详细信息
          trigger: 'item'
        },
        yAxis: {
          type: 'value',
          // 隐藏y轴
          show: false
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            // 显示为区域面积图
            areaStyle: {
              color: '#c6e7ff'
            },
            // 平滑曲线显示
            smooth: true,
            lineStyle: {
              color: '#67beff'
            },
            itemStyle: {
              emphasis: {
                // 禁止鼠标悬浮时高亮
                color: '#eee'
              }
            }
          }
        ],
        // 定位
        grid: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0
        },
        itemStyle: {
          // 去掉坐标项上的圆圈(点)
          opacity: 0
        }
      },
      numOption: {
        xAxis: {
          type: 'category',
          // 关闭横轴自带间隙
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          // 隐藏x轴
          show: false
        },
        // 鼠标悬浮显示数据
        tooltip: {
          trigger: 'item'
        },
        yAxis: {
          type: 'value',
          // 隐藏y轴
          show: false
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            // 显示为区域面积图
            areaStyle: {
              color: '#c6e7ff'
            },
            // 平滑曲线显示
            smooth: true,
            lineStyle: {
              color: '#67beff'
            },
            itemStyle: {
              emphasis: {
                // 禁止鼠标悬浮时高亮
                color: '#eee'
              }
            }
          }
        ],
        // 定位
        grid: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0
        },
        itemStyle: {
          // 去掉坐标项上的圆圈(点)
          opacity: 0
        }
      },
      pieOption: {
        color: ['#4bc1fc', '#78f283', '#e7e702', '#f8726b', '#5085f2', '#8d7fec'],
        title: [
          // 正常的标题
          {
            text: '品类分布',
            left: '3%',
            top: '0',
            textStyle: {
              color: '#666',
              fontSize: 14
            }
          },
          // 饼图内部的文字显示,此处使用标题来实现
          {
            text: '累计订单量',
            subtext: '335',
            textAlign: 'center',
            left: '34.5%',
            top: '39%',
            textStyle: {
              color: '#999',
              fontSize: 14
            },
            subtextStyle: {
              color: '#333',
              fontSize: 28
            }
          }
        ],
        tooltip: {
          trigger: 'item',
          formatter: params => `标题<br />
          ${params.marker}${params.data.name.substring(0, params.data.name.indexOf(' '))}<br />
          数量：${params.data.value}<br />
          占比：${params.data.name.substring(params.data.name.indexOf(' ') + 2)}`
        },
        // 图例样式
        legend: {
          // 布局朝向
          orient: 'vertical',
          // 自右向左图例自身的7%
          right: '7%',
          // 垂直方向在y轴的35%
          y: '35%',
          textStyle: {
            color: '#888'
          }
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            // 设置饼图的大小及空心
            radius: ['47%', '62%'],
            // 设置饼图的位置,前x后y
            center: [240, 200],
            // 设置环形文字和环形的连接线样式
            labelLine: {
              normal: {
                // 第一条线的长度
                length: 8,
                // 第二条线的长度
                length2: 3,
                // 开启平滑过渡
                smooth: true
              }
            },
            itemStyle: {
              normal: {
                // 设置环形区域的间隔,实际上就是设置边框为白色
                borderWidth: 5,
                borderColor: '#fff'
              },
              // 设置边框为白色可以实现间隔的效果,但鼠标悬浮时涉及到阴影就会显露出来白色边框,所以设置鼠标悬浮时的边框颜色跟随环形区域颜色
              emphasis: {
                borderColor: ''
              }
            },
            label: {
              normal: {
                // 改变饼图上文字提示的样式
                formatter: params => params.data.name.substring(0, params.data.name.indexOf(' '))
              }
            },
            data: [
              { id: 1, value: 30, name: '粉面粥店 | 7.34%', percent: '7.34%' },
              { id: 2, value: 92, name: '简餐便当 | 22.49%', percent: '22.49%' },
              { id: 3, value: 89, name: '汉堡披萨 | 21.76%', percent: '21.76%' },
              { id: 4, value: 72, name: '香锅冒菜 | 17.6%', percent: '17.6%' },
              { id: 5, value: 88, name: '小吃炸串 | 21.52%', percent: '21.52%' },
              { id: 6, value: 38, name: '地方菜系 | 9.29%', percent: '9.29%' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      radio: '品类'
    }
  }
}
</script>

<style scoped lang="less">
.progress-view-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 20px 10px;
  .progress-view {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
  }
  .left {
    margin-right: 20px;
    .chart {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px 0 20px;
      .title {
        font-size: 14px;
        color: #999;
        margin-bottom: 5px;
      }
      .num {
        letter-spacing: 1px;
        font-size: 22px;
        color: #333;
      }
    }
    .table {
      padding: 0px 20px;
    }
    .pagination {
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }
  }
  .box-card {
    height: 100%;
  }
  .right {
    .right-header {
      display: flex;
      height: 21px;
      justify-content: space-between;
      align-items: center;
    }
    .pie-chart {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
