import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入原生的Echarts并绑定到vue原型上,便于后续直接在页面中调用其API
import * as echarts from 'echarts'
// 引入vue-echarts并通过组件的形式使用echarts
import Echarts from 'vue-echarts'
// 引入VCharts
import VCharts from 'v-charts'
// 引入百度地图
import BaiduMap from 'vue-baidu-map'

Vue.component('v-chart', Echarts)

Vue.use(VCharts)

Vue.prototype.$echarts = echarts

Vue.use(BaiduMap, {
  /* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
  ak: 'CFM6wESKerVWdUHCT4Hxn5eZppKRMtjo'
})

Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
