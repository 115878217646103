<template>
  <div class="box-card">
    <div>
      <div class="title">{{ title }}</div>
      <div class="value">{{ showData }}</div>
      <div class="chart">
        <slot></slot>
      </div>
    </div>

    <div class="line"></div>

    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonCard',
  data () {
    return {}
  },
  props: ['title', 'showData']
}
</script>

<style scoped lang="less">
.box-card{
  transition: box-shadow 0.4s ease-in-out;
  width: 100%;
  border-radius: 4px;
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;
  .chart {
    height: 50px;
  }
  .title {
    color: #999;
    font-size: 12px;
  }
  .value {
    margin-top: 5px;
    color: #000;
    font-size: 25px;
    letter-spacing: 1px;
  }
  .line {
    margin: 10px 0px;
    border-top: 1px solid #eee;
  }
  .footer {
    font-size: 12px;
    color: #666;
  }
}

.increase {
  width: 0;
  height: 0;
  border-width: 4px;
  border-color: transparent transparent red transparent;
  border-style: solid;
  margin: 0 0 5px 4px
}
.decrease {
  width: 0;
  height: 0;
  border-width: 4px;
  border-color: green transparent transparent transparent;
  border-style: solid;
  margin: 4px 0 0 4px
}

.echarts {
  width: 100%;
  height: 50px;
}
.box-card:hover {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}
</style>
