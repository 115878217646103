<template>
  <common-card :title="'累计销售额'" :showData="'¥ 30,057,124'">
      <template>
        <div class="content">
          <div class="compare">
            <div>日同比&nbsp;</div>
            <span class="emphasis">0.08%</span>
            <div class="increase"></div>
          </div>
          <div class="compare">
            <div>月同比&nbsp;</div>
            <span class="emphasis">24.91%</span>
            <div class="decrease"></div>
          </div>
        </div>
      </template>
      <template #footer>
        昨日销售额&nbsp;&nbsp;<span class="emphasis">¥&nbsp;30,000,000</span>
      </template>
    </common-card>
</template>

<script>
import CommonCard from '@/components/commonCard/index'
export default {
  name: 'SaleView',
  components: {
    CommonCard
  }
}
</script>

<style scoped>
.content {
  font-size: 12px;
  color: #666;
  padding-top: 10px;
  line-height: 18px;
}
</style>
