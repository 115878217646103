<template>
  <div class="test-box">
    <el-button type="primary" @click="showNativeEcharts">基于原生的Ecahrts实现图表</el-button>
    <el-button type="primary" @click="showVueEcharts">基于Vue-Ecahrts实现图表</el-button>
    <el-button type="primary" @click="showVCharts">基于VCahrts实现图表</el-button>
    <el-button type="primary" @click="showBallChart">展示水球图</el-button>
    <el-button type="primary" @click="showWordChart">展示词云图</el-button>
  </div>
</template>

<script>
export default {
  name: 'TestView',
  methods: {
    showNativeEcharts () {
      this.$router.push({
        name: 'echarts'
      })
    },
    showVueEcharts () {
      this.$router.push({
        name: 'vue-echarts'
      })
    },
    showVCharts () {
      this.$router.push({
        name: 'vcharts'
      })
    },
    showBallChart () {
      this.$router.push({
        name: 'ball'
      })
    },
    showWordChart () {
      this.$router.push({
        name: 'word'
      })
    }
  }
}
</script>

<style scoped>
.test-box {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: skyblue;
}
</style>
