<template>
  <common-card :title="'累计订单量'" :showData="'2,002,000'">
      <template>
        <v-chart class="order-chart" :options="option"></v-chart>
      </template>
      <template #footer>
        昨日订单量&nbsp;&nbsp;<span class="emphasis">2,000,000</span>
      </template>
  </common-card>
</template>

<script>
import CommonCard from '@/components/commonCard/index'
export default {
  name: 'OrderView',
  components: {
    CommonCard
  },
  data () {
    return {
      option: {
        xAxis: {
          type: 'category',
          // 关闭横轴自带间隙
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          // 隐藏x轴
          show: false
        },
        // 鼠标悬浮显示数据
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        yAxis: {
          type: 'value',
          // 隐藏y轴
          show: false
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            // 显示为区域面积图
            areaStyle: {
              color: 'purple'
            },
            // 平滑曲线显示
            smooth: true,
            lineStyle: {
              // 去掉边缘线
              width: 0
            }
          }
        ],
        // 定位
        grid: {
          top: 0,
          left: 0,
          bottom: 0,
          right: 0
        },
        itemStyle: {
          // 去掉坐标项上的圆圈(点)
          opacity: 0
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
