<template>
  <div class="top-view">
    <sale-view></sale-view>
    <order-view></order-view>
    <today-users-view></today-users-view>
    <total-users-view></total-users-view>
  </div>
</template>

<script>
import OrderView from '@/views/OrderView/index'
import SaleView from '@/views/SaleView/index'
import TodayUsersView from '@/views/TodayUsersView/index'
import TotalUsersView from '@/views/TotalUsersView/index'
export default {
  name: 'TopView',
  components: {
    OrderView, SaleView, TodayUsersView, TotalUsersView
  }
}
</script>

<style scoped>
.top-view{
  display: flex;
  justify-content: space-around;
}
</style>
