<template>
  <div class="home">
    <top-view />
    <sales-view />
    <progress-view />
    <map-view />
  </div>
</template>

<script>
import TopView from '@/views/TopView/index'
import SalesView from '@/views/SalesView/index'
import ProgressView from '@/views/ProgressView/index'
import MapView from '@/views/MapView/index'

export default {
  name: 'HomeIndex',
  components: {
    TopView,
    SalesView,
    ProgressView,
    MapView
  }
}
</script>

<style>
html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.home {
  height: 100%;
  /* height: 600px; */
  background-color: #eee;
  padding: 10px;
}
.emphasis {
  font-weight: 700;
  color: #333;
}
.compare {
  display: flex;
  align-items: center;
}

</style>
