<template>
  <common-card :title="'累计用户数'" :showData="'1,000,948'">
      <template>
        <v-chart :options="option" class="total-chart"></v-chart>
      </template>
      <template #footer>
        <div class="footer">
          日同比&nbsp;&nbsp;<span class="emphasis">0.02%</span><div class="increase"></div>
          &nbsp;月同比&nbsp;&nbsp;<span class="emphasis">25.03%</span><div class="decrease"></div>
        </div>
      </template>
  </common-card>
</template>

<script>
import CommonCard from '@/components/commonCard/index'
export default {
  name: 'TotalUsersView',
  components: {
    CommonCard
  },
  data () {
    return {
      option: {
        xAxis: {
          // 将x和y轴互换
          type: 'value',
          show: false
        },
        yAxis: {
          type: 'category',
          show: false
        },
        series: [
          {
            data: [120],
            type: 'bar',
            barWidth: '20%',
            // stack属性,会将相同属性值的柱状图合并
            stack: '1',
            color: '#45c946'
          },
          {
            data: [200],
            type: 'bar',
            barWidth: '20%',
            stack: '1',
            color: '#eee',
            itemStyle: {
              emphasis: {
                // 禁止鼠标悬浮时高亮
                color: '#eee'
              }
            }
          },
          // 自定义三角形图标
          {
            data: [120],
            type: 'custom',
            renderItem: function (params, api) {
              // 读取data属性中真实的数据
              const value = api.value(0)
              // 设置图标结束的位置: value的值为终点,0为起点;此处value - 4.2是为了美观
              const endPoint = api.coord([value - 4.2, 0])

              // 返回的实际绘制的图标
              return {
                // 表示绘制的图标有两个(上箭头、下箭头)
                type: 'group',
                // 图标的定位
                position: endPoint,
                children: [
                  // 下三角
                  {
                    type: 'path',
                    shape: {
                      // d,绘制图标,此处为SVG代码中截取
                      d: 'M65.582671 288.791335l446.417329 446.41733 446.417329-446.41733z',
                      x: 0,
                      y: -27,
                      width: 15,
                      height: 30
                    },
                    style: {
                      // 设置填充颜色
                      fill: '#45c946'
                    },
                    // 子图形的路径 (shape.d) 会按照绝对值绘制，不受父容器的宽高或其他布局属性影响(防止形状变形、位置发生偏移)
                    layout: 'cover'
                  },
                  // 上三角
                  {
                    type: 'path',
                    shape: {
                      d: 'M65.582671 735.208665l446.417329-446.41733 446.417329 446.41733z',
                      x: 0,
                      y: -2,
                      width: 15,
                      height: 30
                    },
                    style: {
                      fill: '#45c946'
                    },
                    layout: 'cover'
                  }
                ]
              }
            }
          }
        ],
        grid: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }
      }
    }
  }
}
</script>

<style scoped>
.footer {
  display: flex;
  align-items: center;
}
</style>
