<template>
  <div class="panel">

    <el-menu
      :default-active="activeIndex"
      class="el-menu"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">销售额</el-menu-item>
      <el-menu-item index="2">访问量</el-menu-item>
    </el-menu>

    <div class="line"></div>

    <div class="menu-right">
        <div class="time">
            <el-radio-group v-model="time" size="small">
                <el-radio-button label="今日"></el-radio-button>
                <el-radio-button label="本周"></el-radio-button>
                <el-radio-button label="本月"></el-radio-button>
                <el-radio-button label="今年"></el-radio-button>
            </el-radio-group>
        </div>

        <div class="date">
            <el-date-picker
            v-model="date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            :picker-options="pickerOptions">
            </el-date-picker>
        </div>
    </div>

    <div class="chart">
      <v-chart :options="option" style="height: 270px; width: 100%;"></v-chart>
    </div>

    <!-- 排行榜 -->
    <div class="top-item">
        <div class="list-title">排行榜</div>
        <div class="list-item" v-for="(item, index) in rendData" :key="item.id">
            <div class="list-item-left">
                <div :class="{'top-no': index < 3}" class="list-item-no">{{ index + 1 }}</div>
                <div class="list-item-name">{{ item.name }}</div>
            </div>
            <div class="list-item-value">{{ item.value }}</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SalesView',
  data () {
    return {
      activeIndex: '1',
      option: {
        title: {
          text: '年度销售业绩数据',
          textStyle: {
            color: '#666',
            fontSize: 12
          },
          left: 7,
          top: 15
        },
        // 设置字体样式
        textStyle: {
          color: '#333'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            // 设置悬浮时阴影
            shadowStyle: {
              color: '#ccc',
              opacity: 0.4
            }
          }
        },
        grid: {
          left: '1%',
          right: '0',
          bottom: '12%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisTick: {
              alignWithLabel: true
            },
            // 设置x轴线的颜色
            axisLine: {
              lineStyle: {
                color: '#999'
              }
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              // 隐藏 Y 轴的轴线
              show: false
            },
            axisTick: {
              // 隐藏 Y 轴的刻度线
              show: false
            },
            splitLine: {
              // 设置y轴对应线的样式
              lineStyle: {
                type: 'dotted', // 虚线
                color: '#eee', // 设置透明的灰色
                width: 1
              }
            },
            // 设置y轴数据
            // 起始
            min: 0,
            // 结束
            max: 500,
            // 步长
            interval: 100
          }
        ],
        series: [
          {
            name: '销售额',
            type: 'bar',
            barWidth: '35%',
            data: [10, 52, 200, 334, 40, 330, 220, 290, 240, 100, 200, 80],
            color: '#3398db'
          }
        ]
      },
      // 时间选项
      time: '今年',
      // 日期选择器快捷选项设置
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      // 日期时间值
      date: '',
      // 排行榜数据
      rendData: [
        { id: 1, name: '海底捞', value: '323,234' },
        { id: 2, name: '肯德基', value: '299,132' },
        { id: 3, name: '麦当劳', value: '283,998' },
        { id: 4, name: '必胜客', value: '266,223' },
        { id: 5, name: '西贝筱面村', value: '223,445' },
        { id: 6, name: '汉堡王', value: '219,663' },
        { id: 7, name: '真功夫', value: '200,997' }
      ]
    }
  },
  methods: {
    handleSelect () {
      console.log('哦')
    }
  }
}
</script>

<style scoped lang="less">
.panel {
  transition: box-shadow 0.4s ease-in-out;
  margin: 10px;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  .el-menu {
    padding: 0 30px;
  }
  .chart{
    width: 970px;
    padding: 0 20px;
  }
  .menu-right {
    height: 60px;
    position: absolute;
    right: 20px;
    top: 0;
    display: flex;
    align-items: center;
    .time {
        margin-right: 20px;
    }
  }
}
.top-item {
    font-size: 12px;
    color: #333;
    width: 444px;
    position: absolute;
    top: 65px;
    right: 20px;
    line-height: 1.9rem;
    .list-title {
        color: #666;
        font-weight: 700;
        margin: 5px 0;
    }
    .list-item {
      display: flex;
      justify-content: space-between;
      .list-item-left {
        display: flex;
        align-items: center;
        .list-item-no {
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .list-item-name {
            margin-left: 10px;
        }
      }
    }
}
.top-no {
    color: #fff;
    border-radius: 50%;
    background-color: #409eff;
}
.panel:hover {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}
</style>
